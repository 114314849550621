import React from "react";
import { FaChevronDown } from "react-icons/fa";

// interfaces
import { DropdownButtonProps } from "./Dropdown.interface";

// elements
import Button from "@/shared/Button";

// assets
import styles from "@/assets/styles/shared/dropdown/dropdown-button.module.scss";

function DropdownButton({ placeholder, value, icon, type = "secondary", ...rest }: DropdownButtonProps) {
  return (
    <Button
      iconRight={icon || <FaChevronDown className={styles["locl-dropdown-inner-button__icon"]} />}
      type={type}
      className={styles["locl-dropdown-inner-button"]}
      data-testid="locl-dropdown-button"
      {...rest}
    >
      {placeholder ? (
        <span className={styles["locl-dropdown-inner-button__label"]}>{placeholder}</span>
      ) : null}
      {value ? <span className={styles["locl-dropdown-inner-button__value"]} data-testid="locl-dropdown-inner-button-value">{value}</span> : null}
    </Button>
  );
}

export default DropdownButton;
